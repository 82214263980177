import { api } from "../config";

const exportLeadsApi = async (clientId) => {
    const endpoint = clientId ? "admin/leads/export" : "leads/export";
    try {
        const response = await (clientId
                ? api.post(endpoint, { client_id: clientId }, {
                    responseType: 'blob', // Set the response type to 'blob' to handle binary data
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                : api.get(endpoint, {
                    responseType: 'blob', // Set the response type to 'blob' to handle binary data
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
        );

        // Create a Blob object from the response data
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Generate a URL for the Blob object
        const url = window.URL.createObjectURL(blob);

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'leads.xlsx'); // Set the filename for download
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error exporting data:', error);
        throw error;
    }
};

export default exportLeadsApi;