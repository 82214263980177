import {api} from "../config";

const unassignedLeadsApi = async (leadId) => {
    try {
        const response = await api.post(`assign_lead/${leadId}`, {assigned_id: ""},{
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export default unassignedLeadsApi;