import Modal from "react-bootstrap/Modal";
import "./CenteredModal.css";
const CenteredModal = (props) => {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`integration-modal ${props.className ? props.className : null}`}
    >
      {props.children}
    </Modal>
  );
};

export default CenteredModal;
