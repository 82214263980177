import {api} from "../config";
import {cleanData} from "../../utils/clean-data";

const updateSingleLeadApi = async (leadId, leadData) => {
    try {
        // Clean the leadData by removing null, undefined, or empty values
        const cleanedData = cleanData(leadData);
        let formData;

        // Check if there is a file in the leadData
        if (cleanedData.quotation_offer || cleanedData.action_proven) {
            formData = new FormData();

            // Append each field from cleanedData to the FormData object
            for (const key in cleanedData) {
                if (Object.prototype.hasOwnProperty.call(cleanedData, key)) {
                    formData.append(key, cleanedData[key]);
                }
            }
        }

        const response = await api.post(
            `lead/${leadId}?_method=put`,
            formData || cleanedData,
            {
                headers: {
                    'Content-Type': formData ? 'multipart/form-data' : 'application/json',
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error updating lead:', error);
        throw error;
    }
};

export default updateSingleLeadApi;