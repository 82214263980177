import { ReactSVG } from 'react-svg';
import LoadingSVG from '../../assets/media/Icons/LoadingSVG.svg';
import LoadingSVG2 from '../../assets/media/Icons/LoadingSVG2.svg';
import './LoadingAnimation.css';

const LoadingAnimation = () => {
    return (
        <div className={"loading-container"}>
            <div className="loading-icons-container">
                <ReactSVG src={LoadingSVG} className="svg-icon-1" />
                <ReactSVG src={LoadingSVG2} className="svg-icon-2" />
            </div>
        </div>
    );
};

export default LoadingAnimation;