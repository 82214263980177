import {HiOutlineUsers} from "react-icons/hi";
import {RiListSettingsLine} from "react-icons/ri";
import {BiCheckShield} from "react-icons/bi";
import {PiOfficeChairBold, PiSquaresFourFill} from "react-icons/pi";
import "./Sidebar.css";
import {Dropdown, Nav} from "react-bootstrap";
import {FiSettings} from "react-icons/fi";
import {NavLink} from "react-router-dom";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {GrDocumentPerformance} from "react-icons/gr";
import {LuFileKey2} from "react-icons/lu";
// import { IoPricetags } from "react-icons/io5";
import {useAuth} from "../../../context/AuthContext";
import {FaMeta} from "react-icons/fa6";
import {BsThreeDots} from "react-icons/bs";
import {GiHamburgerMenu} from "react-icons/gi";
import {TbLayoutSidebarLeftCollapse, TbLayoutSidebarLeftExpand,} from "react-icons/tb";
import {Tooltip} from "react-tooltip";

const SidebarComponent = ({setIsExpanded, iconSize, mobileSidebar, isExpanded}) => {
    const {currentUserPermissions, user} = useAuth();
    const toggleSidebar = () => {
        setIsExpanded((prevState) => !prevState);
    };

    const [isHoverSupported, setIsHoverSupported] = useState(true);
    const [tooltipVisible, setTooltipVisible] = useState({}); // Manage which tooltip is visible
    const [visibleMenuItems, setVisibleMenuItems] = useState([]);
    const [hiddenMenuItems, setHiddenMenuItems] = useState([]);
    const menuRef = useRef(null);

    useEffect(() => {
        // Check if the device supports hover and fine pointer
        const mediaQuery = window.matchMedia("(hover: hover) and (pointer: fine)");
        setIsHoverSupported(mediaQuery.matches);

        const handleChange = () => setIsHoverSupported(mediaQuery.matches);
        mediaQuery.addEventListener('change', handleChange);

        return () => mediaQuery.removeEventListener('change', handleChange);
    }, []);

    const handleTouchStart = (index) => {
        // Show the tooltip when the user touches the item
        setTooltipVisible((prevState) => ({...prevState, [index]: true}));

        // Hide the tooltip after 2 seconds (or any duration)
        setTimeout(() => {
            setTooltipVisible((prevState) => ({...prevState, [index]: false}));
        }, 2000); // 2 seconds delay
    };

    // const accessToken = Cookies.get('access_token') || user?.user["access-token"];
    const mainMenuItems = [{
        id: 1, icon: <PiSquaresFourFill size={iconSize}/>, label: "Dashboard", link: "",
    }, {id: 2, icon: <HiOutlineUsers size={iconSize}/>, label: "Leads", link: "leads"}, {
        id: 3, icon: <PiOfficeChairBold size={iconSize}/>, label: "Team", link: "team", permission: "member",
    }, {
        id: 4,
        icon: <RiListSettingsLine size={iconSize}/>,
        label: "Integrations",
        link: "integrations",
        permission: "integration",
    }, {
        id: 5, icon: <FaMeta size={iconSize}/>, label: "Meta Business Suite", link: "meta-business-suite",
    }, {
        id: 6, icon: <GrDocumentPerformance size={iconSize}/>, label: "Reports", link: "reports",
    }, {
        id: 7,
        icon: <LuFileKey2 size={iconSize}/>,
        label: "Roles & Permissions",
        link: "permissions",
        permission: "role",
    }, // { icon: <IoPricetags size={iconSize} />, label: "Subscription", link: "subscription", permission: "subscription" }
    ].filter((item) => {
        // Exclude items if the user doesn't have any CRUD permissions associated with it
        if (item.label === "Leads") {
            return true;
        }
        if (item.permission) {
            const permissions = ["list", "create", "edit", "delete", "config", "restore",];
            return permissions.some((permission) => currentUserPermissions.includes(`${item.permission}-${permission}`) || user?.user?.role === "Owner",);
        }
        return true;
    });

    const sideBarFooterItems = [// { icon: <BiHelpCircle size={iconSize} />, label: "Help Center", link: "support" },
        {
            id: 8, icon: <BiCheckShield size={iconSize}/>, label: "Privacy Policy", link: "privacy",
        }, {
            id: 9, icon: <FiSettings size={iconSize}/>, label: "Account Setting", link: "account-settings",
        }, {
            id: 10, icon: <BiCheckShield size={iconSize}/>, label: "Terms & Conditions", link: "terms",
        },];

    // Measure the width of the screen and determine how many items can fit
    useLayoutEffect(() => {
        const handleIcons = () => {
            if (!menuRef.current) return;
            const containerWidth = menuRef?.current?.offsetWidth;
            const itemWidth = 90; // Estimate the width of each item (icon + padding)
            const visibleItemsCount = Math.floor(containerWidth / itemWidth);

            const visibleItems = mainMenuItems.slice(0, visibleItemsCount);
            const hiddenItems = mainMenuItems.slice(visibleItemsCount);

            // Only update state if the number of visible/hidden items has changed
            if (
                visibleItems.length !== visibleMenuItems.length ||
                hiddenItems.length !== hiddenMenuItems.length
            ) {
                setVisibleMenuItems(visibleItems);
                setHiddenMenuItems(hiddenItems);
            }
        };

        handleIcons(); // Call once on mount
        window.addEventListener("resize", handleIcons);

        return () => window.removeEventListener("resize", handleIcons);
    }, [mainMenuItems]);

    return mobileSidebar ? (<div className="mobileFooter" ref={menuRef}>
            {visibleMenuItems.map(item => (<div key={item?.id}>
                    <Nav.Link
                        as={NavLink}
                        to={`/${item.link}`}
                        className="menu-item-mobile"
                        id={`navlink-${item?.id}`}
                        onTouchStart={() => !isHoverSupported && handleTouchStart(item?.id)}
                    >
                        <div className="menu-icon-label-container">
                            <div className="icon-container">{item?.icon}</div>
                            <div className="label-container text-center">
                                {item?.label.split(' ')[0]} {/* Display only the first word */}
                            </div>
                        </div>
                    </Nav.Link>
                    {!isExpanded && (<Tooltip
                            anchorSelect={`#navlink-${item?.id}`}
                            content={item?.label}
                            className="bg-dark text-white"
                            isOpen={isHoverSupported ? undefined : tooltipVisible[item?.id]}
                        />)}
                </div>))}

            <Dropdown drop="up-left">
                <Dropdown.Toggle id="dropdown-autoclose-true" variant="none">
                    <GiHamburgerMenu size={iconSize}/>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {hiddenMenuItems.map(item => (<Dropdown.Item
                            key={item?.id}
                            as={NavLink}
                            to={`/${item.link}`}
                            className="dropdown-item"
                            id={`dropdown-navlink-${item?.id}`}
                            onTouchStart={() => !isHoverSupported && handleTouchStart(item?.id)}
                        >
                            <div className="menu-icon-label-container">
                                <div>{item.icon}</div>
                                <div className="label-container">
                                    {item?.label.split(' ')[0]} {/* Display only the first word */}
                                </div>
                            </div>
                            {!isExpanded && (<Tooltip
                                    anchorSelect={`#dropdown-navlink-${item?.id}`}
                                    content={item.label}
                                    className="bg-dark text-white"
                                    isOpen={isHoverSupported ? undefined : tooltipVisible[item?.id]}
                                />)}
                        </Dropdown.Item>))}
                    {sideBarFooterItems.map(item => (<Dropdown.Item
                            key={item?.id}
                            as={NavLink}
                            to={`/${item.link}`}
                            className="dropdown-item"
                            id={`dropdown-footer-${item?.id}`}
                            onTouchStart={() => !isHoverSupported && handleTouchStart(item?.id)}
                        >
                            <div className="menu-icon-label-container">

                                <div>{item.icon}</div>
                                <div className="label-container">
                                    {item?.label.split(' ')[0]} {/* Display only the first word */}
                                </div>
                            </div>
                            {!isExpanded && (<Tooltip
                                    anchorSelect={`#dropdown-footer-${item?.id}`}
                                    content={item.label}
                                    className="bg-dark text-white"
                                    isOpen={isHoverSupported ? undefined : tooltipVisible[item?.id]}
                                />)}
                        </Dropdown.Item>))}
                </Dropdown.Menu>
            </Dropdown>
        </div>) : (<div className={`sidebarBS ${isExpanded ? "expanded" : ""}`}>
            <div className={"text-center sidebar-top-section position-relative"}>
                {isExpanded ? (<h2 className={"main-menu"}>main menu</h2>) : (
                    <div className={"d-flex flex-column align-items-center"}>
                        <TbLayoutSidebarLeftExpand
                            role={"button"}
                            onClick={toggleSidebar}
                            size={30}
                        />
                        <BsThreeDots className={"my-3"}/>
                    </div>)}
                {isExpanded ? (<TbLayoutSidebarLeftCollapse
                        role={"button"}
                        onClick={toggleSidebar}
                        size={30}
                        className={"collapse-sidebar-icon"}
                    />) : null}
            </div>
            <div className="sidebar-content sidebar-nav-container">
                <Nav className="flex-column align-items-center" variant={"pills"}>
                    {mainMenuItems.map((item, index) => (<Nav.Item key={index}>
                            <Nav.Link
                                as={NavLink}
                                to={`/${item.link}`}
                                className="menu-item my-1"
                                id={`navlink-${index}`}
                            >
                                <div className="text-center"> {item.icon}</div>
                                <div className="menu-item_label ms-3">{item.label}</div>
                                {isExpanded ? null : (<Tooltip
                                        anchorSelect={`#navlink-${index}`}
                                        content={item.label}
                                        className={"bg-dark text-white"}
                                        openOnClick={true}
                                    />)}
                            </Nav.Link>
                        </Nav.Item>))}
                </Nav>

                <div className={"mt-3"}>
                    <div className={"text-center sidebar-top-section"}>
                        {isExpanded ? (<h2 className={"main-menu"}>useful links</h2>) : (
                            <BsThreeDots className={"my-3"}/>)}
                    </div>
                    <Nav className="flex-column" variant={"pills"}>
                        {sideBarFooterItems.map((item, index) => (<Nav.Item key={index}>
                                <Nav.Link
                                    as={NavLink}
                                    to={`/${item.link}`}
                                    className="menu-item my-1"
                                    id={`footer-navlink-${index}`}
                                >
                                    <div className="text-center">{item.icon}</div>
                                    <div className="menu-item_label ms-3">{item.label}</div>
                                    {isExpanded ? null : (<Tooltip
                                            anchorSelect={`#footer-navlink-${index}`}
                                            content={item.label}
                                            className={"bg-dark text-white"}
                                            openOnClick={true}
                                        />)}
                                </Nav.Link>
                            </Nav.Item>))}
                    </Nav>
                </div>
            </div>
        </div>);
};

export default SidebarComponent;
