import {api} from "../../config";
import {toast} from "react-toastify";

const sendMessageApi = async (data) => {
    try {
        const response = await api.post("send-message", data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error sending data:', error);
        toast.error(error?.response?.data?.message, {position: "bottom-right", theme: "dark"});
        throw error;
    }
};

export default sendMessageApi;