import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ClientProvider } from "./context/ClientContext";
import { AuthProvider } from "./context/AuthContext";
import { RoleProvider } from "./context/RoleContext";
import { IntegrationProvider } from "./context/IntegrationContext";
import { LeadsPaginationProvider } from "./context/LeadsPaginationContext";
import { BrowserRouter as Router } from "react-router-dom";
import { MetaBusinessChatProvider } from "./context/MetaBusinessChatContext";
import { CheckOutProvider } from "./context/CheckOutContext";

const Providers = ({ children }) => (
  <AuthProvider>
    <ClientProvider>
      <RoleProvider>
        <IntegrationProvider>
          <MetaBusinessChatProvider>
            <LeadsPaginationProvider>
              <CheckOutProvider>{children}</CheckOutProvider>
            </LeadsPaginationProvider>
          </MetaBusinessChatProvider>
        </IntegrationProvider>
      </RoleProvider>
    </ClientProvider>
  </AuthProvider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Providers>
        <App />
      </Providers>
    </Router>
  </React.StrictMode>,
);
