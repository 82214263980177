import { api } from "../../config";

const getUserPagesApi = async ({ signal }) => {
  try {
    const response = await api.post("userPages", {
      headers: {
        "Content-Type": "application/json",
      },
      signal,
    });
    return response.data;
  } catch (error) {
    console.error("Error sending data:", error);
    // toast.error(error?.response?.data?.message, {position: "bottom-right", theme: "dark"});
    throw error;
  }
};

export default getUserPagesApi;
