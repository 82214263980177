import {api} from "../config";

const getAllLeadsApi = async (clientId, records = 10, currentPage = 1) => {
    const url = clientId ? `admin/leads?client_id=${clientId}&per_page=${records}&current_page=${currentPage}` : `/lead?per_page=${records}&current_page=${currentPage}`;
    try {
        const response = await api.get(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export default getAllLeadsApi;