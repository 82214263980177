import {api, BackEnd_EndPoint} from "../config";

const updateSingleRoleApi = async (roleId, selectedPermissions) => {
    const updatedData = {
        permission: selectedPermissions
    }
    try {
        const response = await api.post(`${BackEnd_EndPoint}update_permissions/${roleId}`, updatedData,{
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating role:', error);
        throw error;
    }
};

export default updateSingleRoleApi;