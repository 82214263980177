import {api, BackEnd_EndPoint} from "../config";

const readSingleNotificationApi = async (notificationId) => {
    try {
        const response = await api.post(`${BackEnd_EndPoint}makeNotificationRead/${notificationId}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export default readSingleNotificationApi;