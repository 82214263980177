import { api } from "../../config";
import importLeads from "../../leads/import-leads.api";
import { toast } from "react-toastify";

const downloadTikTokLeadsAPI = async (advertiserID, pageID) => {
  try {
    // Step 1: Download Excel file
    const response = await api.get(
      `download_leads?advertiser_id=${advertiserID}&page_id=${pageID}`,
      {
        responseType: "blob",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    // Step 2: Create FormData object to prepare for upload
    const formData = new FormData();
    formData.append(
      "file",
      response.data,
      `leads-${new Date().toISOString()}.xlsx`,
    );

    // Step 3: Upload FormData to the specified endpoint
    const uploadResponse = await importLeads(formData);

    // Step 4: Handle response from the upload endpoint
    if (uploadResponse?.status !== 200) {
      throw new Error(
        `Failed to upload file: ${uploadResponse.status} ${uploadResponse.statusText}`,
      );
    }

    toast.success(uploadResponse?.data?.message || "Upload successful", {
      position: "bottom-right",
      theme: "dark",
    });

    // Step 5: Trigger the download after successful upload
    // const blob = new Blob([response.data], {
    //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // });

    // FOR THE DOWNLOAD PART !
    // const url = window.URL.createObjectURL(blob);
    // const link = document.createElement("a");
    // link.href = url;
    // link.setAttribute("download", `leads-${new Date().toISOString()}.xlsx`);
    // document.body.appendChild(link);
    // link.click();

    // Clean up
    // document.body.removeChild(link);
    // window.URL.revokeObjectURL(url);

    return response;
  } catch (error) {
    console.error("Error downloading or uploading leads:", error);
    toast.error("Error downloading or uploading leads", {
      position: "bottom-right",
      theme: "dark",
    });
    throw error;
  }
};

export default downloadTikTokLeadsAPI;
