import { Badge, Dropdown, Stack } from "react-bootstrap";
import userImg from "../../../assets/media/user.png";
import headerLogo from "../../../assets/media/logo.svg";
import "./Header.css";
import { FaBell } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { IoIosLogIn, IoIosLogOut } from "react-icons/io";
import { useAuth } from "../../../context/AuthContext";
import { useEffect, useState } from "react";
import getAllNotificationsApi from "../../../services/notifications/get-all-notifications.api";
import readSingleNotificationApi from "../../../services/notifications/read-notification.api";
import { toast } from "react-toastify";
import NotificationItem from "./NotificationItem.component";
import markAllAsReadApi from "../../../services/notifications/mark-all.api";
import { TbBellZFilled } from "react-icons/tb";
import getCurrentFormattedDate from "../../../utils/get-current-formatted-date";

const HeaderComponent = () => {
  const { user, logout } = useAuth();
  const userData = user?.user;
  const [notifications, setNotifications] = useState({
    all: [],
    read: [],
    unread: [],
    count: 0,
    activeTab: "all",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllNotificationsApi();
        const count = response.data.count;
        const allNotifications = response.data.notifications;
        const unreadNotifications = allNotifications.filter(
          (notification) => !notification.read_at,
        );
        const readNotifications = allNotifications.filter(
          (notification) => notification.read_at,
        );

        setNotifications({
          all: allNotifications,
          read: readNotifications,
          unread: unreadNotifications,
          count: count,
          activeTab: "all",
        });
      } catch (error) {
        console.error("Error fetching notifications:", error);
        // Handle error, show error message, or redirect if needed
      }
    };

    fetchData();
  }, []);

  const readSingleNotification = async (notificationId) => {
    try {
      await readSingleNotificationApi(notificationId);

      setNotifications((prevNotifications) => {
        if (!prevNotifications) {
          return prevNotifications;
        }

        const updatedNotifications = prevNotifications.all.map(
          (notification) => {
            if (notification.id === notificationId) {
              return { ...notification, read_at: getCurrentFormattedDate() };
            }
            return notification;
          },
        );

        const updatedUnreadNotifications = updatedNotifications.filter(
          (notification) => !notification.read_at,
        );
        const updatedReadNotifications = updatedNotifications.filter(
          (notification) => notification.read_at,
        );

        // Decrement count when marking a notification as read
        const updatedCount =
          prevNotifications.count > 0 ? prevNotifications.count - 1 : 0;

        return {
          ...prevNotifications,
          all: updatedNotifications,
          read: updatedReadNotifications,
          unread: updatedUnreadNotifications,
          count: updatedCount,
        };
      });
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Error marking notification as read",
        {
          position: "bottom-right",
          theme: "dark",
        },
      );
    }
  };

  const markAllAsRead = async () => {
    try {
      await markAllAsReadApi();

      // Update the state to mark all unread notifications as read
      setNotifications((prevNotifications) => ({
        ...prevNotifications,
        all: prevNotifications?.all?.map((notification) => ({
          ...notification,
          read_at: getCurrentFormattedDate(),
        })),
        read: [...prevNotifications.read, ...prevNotifications.unread],
        unread: [],
        count: 0,
        activeTab: "all", // Set the activeTab to 'all' after marking all as read
      }));
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "bottom-right",
        theme: "dark",
      });
    }
  };

  const conditionalUserImg = user?.user?.photo
    ? process.env.REACT_APP_PROFILE_PIC_ENDPOINT + user?.user?.photo
    : userImg;

  return (
    <>
      <div className="header-container">
        <Stack
          direction={"horizontal"}
          className={
            "justify-content-between align-items-center flex-column flex-lg-row"
          }
        >
          <Link to={"/"}>
            <img
              src={headerLogo}
              width={135}
              height={40}
              alt={"DV Connect Logo"}
            />
          </Link>
          {/*<div className={"search-box my-2"}>*/}
          {/*    <div className={"search-box_input"}>*/}
          {/*        <InputGroup>*/}
          {/*            <Form.Control*/}
          {/*                type={"search"}*/}
          {/*                placeholder={"Search"}*/}
          {/*                aria-label="Large"*/}
          {/*                aria-describedby="inputGroup-sizing-sm"*/}
          {/*            />*/}
          {/*        </InputGroup>*/}
          {/*        <BiSearch className={"search-icon"} size={30} color={"white"}/>*/}
          {/*    </div>*/}
          {/*</div>*/}
          {user ? (
            <div
              className={"d-flex justify-content-between align-items-center"}
            >
              <Dropdown
                className="d-inline mx-2 notification-dropdown"
                autoClose="outside"
              >
                <Dropdown.Toggle id="dropdown-autoclose-outside">
                  <FaBell size={25} color={"white"} />
                  <Badge pill className={"notification-badge"}>
                    {notifications?.count || 0}
                  </Badge>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{ maxHeight: "400px", overflowY: "scroll" }}
                >
                  <div className={"notifications-menu"}>
                    {notifications?.unread?.length > 0 ? (
                      <div
                        className={
                          "w-100 d-flex justify-content-between align-items-center mb-4"
                        }
                      >
                        <div className={"d-flex flex-column"}>
                          <div className={"mainColor fw-bold fs-6"}>
                            New for you{" "}
                            {notifications?.unread?.length
                              ? notifications?.unread?.length
                              : null}
                          </div>
                          <small className={"text-muted"}>
                            Read Notifications{" "}
                            {notifications?.read?.length
                              ? notifications?.read?.length
                              : null}
                          </small>
                        </div>

                        <div onClick={markAllAsRead} role={"button"}>
                          Mark All As Read
                        </div>
                      </div>
                    ) : (
                      <center className={"mainColor fw-bold fs-6 mb-4"}>
                        You're all caught up! No new notifications.
                      </center>
                    )}
                    {notifications.all.length > 0 ? (
                      <NotificationItem
                        userImg={conditionalUserImg}
                        notifications={notifications?.all}
                        onRead={readSingleNotification}
                      />
                    ) : (
                      <center className={"empty-placeholder"}>
                        <TbBellZFilled
                          size={100}
                          className={"mainColor opacity-50"}
                        />
                      </center>
                    )}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <div className={"mx-3 text-white"}>
                {userData?.name || "User"}
              </div>
              <div>
                <Dropdown className="d-inline mx-2 profile-dropdown-container">
                  <Dropdown.Toggle
                    id="dropdown-autoclose-true"
                    className={"profile-dropdown-button"}
                  >
                    <img
                      src={conditionalUserImg}
                      alt={"Profile Pic"}
                      className={"profile-picture"}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className={"profile-dropdown-menu p-3"}>
                    <div className={"text-center"}>
                      <img
                        src={conditionalUserImg}
                        alt={"Profile Pic"}
                        className={"profile-picture-dropdown"}
                      />
                      <p>{userData?.name || "User"}</p>
                      <Link to={"/account-settings"}>
                        <button className={"pricing-button text-uppercase"}>
                          profile
                        </button>
                      </Link>
                      <div className={"text-start mt-3"}>
                        <div>User Guide</div>
                        <div>Support</div>
                        {user ? (
                          <div
                            className={
                              "d-flex justify-content-between text-danger"
                            }
                            onClick={() => logout()}
                            role={"button"}
                          >
                            <div>Logout</div>
                            <div>
                              <IoIosLogOut />
                            </div>
                          </div>
                        ) : (
                          <Link
                            to={"/client/login"}
                            className={
                              "d-flex justify-content-between text-success"
                            }
                          >
                            <div>Login</div>
                            <div>
                              <IoIosLogIn />
                            </div>
                          </Link>
                        )}
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          ) : (
            <Link
              to={"/client/login"}
              className={"d-flex justify-content-between text-white"}
            >
              <div>Login</div>
              <div>
                <IoIosLogIn />
              </div>
            </Link>
          )}
        </Stack>
      </div>
    </>
  );
};

export default HeaderComponent;
