import {api} from "../config";

const deleteAdminMemberApi = async (id) => {
    try {
        const response = await api.delete(`admin/member/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error deactivating member:', error);
        throw error;
    }
};

export default deleteAdminMemberApi;