import { createContext, useContext, useState } from "react";
// Step 1: Create the Context
const CheckOutContext = createContext();

// Step 2: Define the Provider Component
export const CheckOutProvider = ({ children }) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [productName, setProductName] = useState("");

  const setPlan = (plan) => {
    setProductName(plan?.name);
    setTotalPrice(plan?.price);
  };

  // Step 3: Provide the context values
  const contextValues = {
    totalPrice,
    setTotalPrice,
    productName,
    setProductName,
    setPlan,
  };

  return (
    <CheckOutContext.Provider value={contextValues}>
      {children}
    </CheckOutContext.Provider>
  );
};

// Custom hook to consume the context
export const useCheckOutContext = () => useContext(CheckOutContext);
