import axios from 'axios';
import Cookies from "js-cookie";
const BackEnd_EndPoint = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});


api.interceptors.request.use(
    (config) => {
        const userData = Cookies.get('userData');
        const userDataString = userData && JSON.parse(userData);
        if (userData) {
            config.headers.Authorization = `Bearer ${userDataString.token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export { api, BackEnd_EndPoint };