import {api, BackEnd_EndPoint} from "../config";

const createTeamMemberApi = async (teamMemberData) => {
    try {
        const response = await api.post(`${BackEnd_EndPoint}member`, teamMemberData,{
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error sending data:', error);
        throw error;
    }
};

export default createTeamMemberApi;