import { createContext, useContext, useState } from 'react';
// Step 1: Create the Context
const LeadsPaginationContext = createContext();

// Step 2: Define the Provider Component
export const LeadsPaginationProvider = ({ children }) => {
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);

    // Step 3: Provide the context values
    const contextValues = {
        totalPages,
        setTotalPages,
        currentPage,
        setCurrentPage,
        recordsPerPage,
        setRecordsPerPage
    };

    return (
        <LeadsPaginationContext.Provider value={contextValues}>
            {children}
        </LeadsPaginationContext.Provider>
    );
};

// Custom hook to consume the context
export const useLeadsPaginationContext = () => useContext(LeadsPaginationContext);