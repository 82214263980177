import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const NotificationItem = ({ notifications, onRead, userImg }) => {
  return notifications?.map((notification) => (
    <Dropdown.Item
      key={notification?.id}
      as={Link}
      to={`/leads/${notification?.data?.lead_id}`}
      className={"notification-item"}
      style={{ height: "70px" }}
      onClick={notification?.read_at ? null : () => onRead(notification?.id)}
    >
      <div
        style={{
          color: `${notification?.read_at ? "black" : "#283C46"}`,
          backgroundColor: `${notification?.read_at ? "#FFFFFF" : "rgba(146, 192, 32, 0.12)"}`,
        }}
        className={
          "rounded-2 h-100 d-flex justify-content-center align-items-center"
        }
      >
        {notification?.data?.lead_createdBy && (
          <span>
            <img
              src={userImg}
              alt={"Profile Pic"}
              className={"profile-picture me-2"}
            />
            A lead has been created by {notification?.data?.lead_createdBy}.
          </span>
        )}
        {notification?.data?.lead_assignedBy && (
          <div>
            A lead has been updated by {notification?.data?.lead_assignedBy}.
          </div>
        )}
        <div
          className={"greenDot"}
          style={{ color: notification?.read_at ? "#CDCDCD" : "#92C020" }}
        />
      </div>
    </Dropdown.Item>
  ));
};

export default NotificationItem;
