import {api} from "../config";

const createLeadApi = async (leadData, adminFlag) => {
    const url = adminFlag ? 'admin/leads' : 'lead';
    try {
        const response = await api.post(url, leadData,{
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error sending data:', error);
        throw error;
    }
};

export default createLeadApi;