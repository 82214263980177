import {api, BackEnd_EndPoint} from "../config";

const getSingleRoleApi = async (roleId) => {
    try {
        const response = await api.get(`${BackEnd_EndPoint}role/${roleId}`,{
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export default getSingleRoleApi;