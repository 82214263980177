import { api } from "../config";

const exportClientsApi = async () => {
    try {
        const response = await api.get("admin/clients/export", {
            responseType: 'blob', // Set the response type to 'blob' to handle binary data
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        });

        // Create a Blob object from the response data
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Generate a URL for the Blob object
        const url = window.URL.createObjectURL(blob);

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'clients.xlsx'); // Set the filename for download
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error exporting data:', error);
        throw error;
    }
};

export default exportClientsApi;
