import HeaderComponent from "./Header/Header.component";
import SidebarComponent from "./Sidebar/Sidebar.component";
import { Outlet } from "react-router-dom";
import CenteredModal from "../Shared/modals/CenteredModal/CenteredModal";
import ImportModalContent from "../../pages/Integrations/IntegratedPlatform/ImportModalContent";
import { useIntegrationContext } from "../../context/IntegrationContext";
import {useLayoutEffect, useState} from "react";
const Layout = () => {
  const { showIntegrationModal, setShowIntegrationModal } =
    useIntegrationContext();

    const [isExpanded, setIsExpanded] = useState(true);
    const [mobileSidebar, setMobileSidebar] = useState(false);

    const iconSize = mobileSidebar ? 22 : 20;

    useLayoutEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 576) {
                setMobileSidebar(true);
                setIsExpanded(false);
            } else if (window.innerWidth <= 768) {
                setMobileSidebar(false);
                setIsExpanded(false);
            } else {
                setMobileSidebar(false);
                setIsExpanded(true);
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

  return (
    <>
      <HeaderComponent />
      <div className={"d-flex justify-content-center justify-content-md-between"}>
        <SidebarComponent isExpanded={isExpanded} mobileSidebar={mobileSidebar} setIsExpanded={setIsExpanded} iconSize={iconSize} />
          <main className={"mb-5 container"} style={{ marginTop: "1rem" }}>
            <Outlet />
          </main>
      </div>
      <CenteredModal
        show={showIntegrationModal}
        children={
          <ImportModalContent
            handleClose={() => setShowIntegrationModal(false)}
          />
        }
        onHide={() => setShowIntegrationModal(false)}
      />
    </>
  );
};

export default Layout;
