import { api, BackEnd_EndPoint } from "../../config";

const updateAccessTokenApi = async (leadData) => {
  try {
    const response = await api.post(
      `${BackEnd_EndPoint}access_token`,
      leadData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error sending data:", error);
    throw error;
  }
};

export default updateAccessTokenApi;
