import {api, BackEnd_EndPoint} from "../config";

const importLeads = async (data) => {
    try {
        const response = await api.post(`${BackEnd_EndPoint}leads/import`, data,{
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error sending data:', error);
        throw error;
    }
};

export default importLeads;