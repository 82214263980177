import { api } from "../../config";
import { toast } from "react-toastify";

const getMessagesForChatApi = async (data) => {
  try {
    const { signal, ...requestData } = data;
    const response = await api.post("messages", requestData, {
      headers: {
        "Content-Type": "application/json",
      },
      signal,
    });
    return response.data;
  } catch (error) {
    console.error("Error sending data:", error);
    toast.error(error?.response?.data?.message, {
      position: "bottom-right",
      theme: "dark",
    });
    throw error;
  }
};

export default getMessagesForChatApi;
