import Lottie from "lottie-react";
import importAnimation from "../../../assets/media/animations/Import.json";
import {HiMiniXMark} from "react-icons/hi2";

const ImportModalContent = ({handleClose}) => {

    return (
        <div className={"text-center d-flex flex-column align-items-center justify-content-between px-5 pb-4"}>
            <div className={"position-relative w-100"}>
                <Lottie animationData={importAnimation} className={"import-animation mx-auto"} />
                <div className={"modal-close-icon"} onClick={()=> handleClose()}><HiMiniXMark size={25} color={"#E35757"} /></div>
            </div>
            <p className={"fs-5 fw-bold text-uppercase import-title"}>
                IMPORT PAST LEADS
            </p>
            <p className={"import-description"}>
               We have found that you have over (50) leads in different Facebook pages. You can import them all in one go.
            </p>
            <div className={"horizontal-divider mt-2 mb-4"}/>
            <div className={"mt-5 submit-btn text-uppercase"} onClick={()=> handleClose()}>
                Start
            </div>
        </div>
    );
};

export default ImportModalContent;