import {api, BackEnd_EndPoint} from "../config";
import {toast} from "react-toastify";

const autoAssignLeadsApi = async () => {
    try {
        const response = await api.post(`${BackEnd_EndPoint}auto_assign`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        toast.success(response?.data?.message, { position: "bottom-right", theme: "dark"});
        return response.data;
    } catch (error) {
        console.error('Error in Auto Assign Leads:', error);
        toast.error(error?.response?.data?.message, { position: "bottom-right", theme: "dark"});
        throw error;
    }
};

export default autoAssignLeadsApi;
