import { createContext, useContext, useEffect, useState } from "react";
import {toast} from "react-toastify";
import getSingleRoleApi from "../services/roles/get-single-role.api";
import updateRoleApi from "../services/roles/update-role.api";
import {useAuth} from "./AuthContext";
import Cookies from "js-cookie";

const RoleContext = createContext();

const RoleProvider = ({ children }) => {
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [selectedRoleName, setSelectedRoleName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [allPermissions, setAllPermissions] = useState([]);
    const [defaultKey, setDefaultKey] = useState(null);
    const [roleId, setRoleId] = useState(null);
    const { user, setCurrentUserPermissions } = useAuth();
    const [dynamicOwnersRolesData, setDynamicOwnersRolesData] = useState([]);
    const [createRole, setCreateRole] = useState(false);
    useEffect(() => {
        if (!user) return;

        const fetchPermissions = async () => {
            const userPermissions = JSON.parse(Cookies.get('userPermissions'));
            setCurrentUserPermissions(userPermissions);
            setLoading(true);
        };

        fetchPermissions();
    }, [user]);

    const handleTabSelect = (selectedKey) => {
        const selectedRole = roles.find(role => role.name === selectedKey);
        if (selectedRole) {
            setDefaultKey(selectedKey);
            setSelectedRoleName(selectedRole.name);
            setRoleId(selectedRole.id);
            getSingleRoleData(selectedRole.id);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateRoleApi(roleId, selectedPermissions);
            toast("Role updated successfully", { type: "success", position: "bottom-right", theme: "dark" });
        } catch (error) {
            toast.error(error?.response?.data?.message || "Failed to update role", { type: "error", position: "bottom-right", theme: "dark" });
        }
    };

    const handleSwitchChange = (permissionName) => {
        setSelectedPermissions(prevSelectedPermissions => {
            if (prevSelectedPermissions.includes(permissionName)) {
                return prevSelectedPermissions.filter(name => name !== permissionName);
            } else {
                return [...prevSelectedPermissions, permissionName];
            }
        });
    };

    const getSingleRoleData = async (id) => {
        setLoading(true);
        try {
            const response = await getSingleRoleApi(id);
            setPermissions(response?.data?.permissions || []);
            setSelectedPermissions(response?.data?.permissions?.map(permission => permission.name) || []);
            setLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.message || "Failed to fetch role data", { position: "bottom-right", theme: "dark" });
            setLoading(false);
        }
    };

    const handleVerticalTabSelect = (selectedKey) => {
        const selectedRole = dynamicOwnersRolesData.find((role) => role.key === selectedKey);
        if (selectedRole) {
            setDefaultKey(selectedKey);
        }
    };
    const value = {
        roleId,
        setRoleId,
        getSingleRoleData,
        roles,
        setRoles,
        permissions,
        setPermissions,
        selectedRoleName,
        setSelectedRoleName,
        createRole,
        setCreateRole,
        loading,
        setLoading,
        allPermissions,
        setAllPermissions,
        selectedPermissions,
        setSelectedPermissions,
        dynamicOwnersRolesData,
        setDynamicOwnersRolesData,
        defaultKey,
        setDefaultKey,
        handleTabSelect,
        handleSubmit,
        handleSwitchChange,
        handleVerticalTabSelect,
    };

    return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
};

const useRoleContext = () => {
    const context = useContext(RoleContext);
    if (!context) {
        throw new Error("useRoleContext must be used within a RoleProvider");
    }
    return context;
};

export { RoleProvider, useRoleContext };
