import { api } from "../../config";

const disconnectFromTikTokAPI = async () => {
  try {
    const response = await api.post("tiktok/disconnect", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error sending data:", error);
    throw error;
  }
};

export default disconnectFromTikTokAPI;
