import { Navigate } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext";

function ProtectedRouteAdmin({ isSignedIn,
                                 // requiredRoles,
                                 children }) {
    const { user } = useAuth();
    // const userRole = user.user.role;
    if (isSignedIn) {
        if (user?.user?.flag === "user"){
            return <Navigate to="/"/>;
        }
        return children;
    } else {
        return <Navigate to="/admin/login"/>;
    }
}

export default ProtectedRouteAdmin;
