import {api} from "../config";
import {toast} from "react-toastify";
const updateAdminMemberStatusApi = async (id) => {
    try {
        const response = await api.delete(`admin/client/${id}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        toast.success('Member updated successfully', {position: "bottom-right", theme: "dark"});
        return response.data;
    } catch (error) {
        toast.error(error?.response?.data?.message, {position: "bottom-right", theme: "dark"});
        throw error;
    }
};

export default updateAdminMemberStatusApi;