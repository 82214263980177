import { api } from "../../config";

const DisconnectFromFBApi = async (FBAccID) => {
  try {
    const response = await api.post(
      "disconnect",
      { user_id: FBAccID?.toString() },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error logout:", error);
    throw error;
  }
};

export default DisconnectFromFBApi;
