import {Navigate} from 'react-router-dom';
import {useAuth} from "../../context/AuthContext";

function Protected({isSignedIn,
                       // requiredRoles,
                       requiredPermissions,
                       children}) {
    const { currentUserPermissions, user } = useAuth();

    if (isSignedIn) {
        if (user?.user?.flag === "admin"){
            return <Navigate to="/admin/dashboard"/>;
        } else if ((requiredPermissions && !requiredPermissions.every(permission => currentUserPermissions.includes(permission)))) {
            return <Navigate to="/unauthorized"/>;
        }
        return children;
    } else {
        return <Navigate to="/client/login"/>;
    }
}

export default Protected;
